import { render, staticRenderFns } from "./ProductPopularSliderMobile.vue?vue&type=template&id=130f2999&scoped=true"
import script from "./ProductPopularSliderMobile.vue?vue&type=script&lang=js"
export * from "./ProductPopularSliderMobile.vue?vue&type=script&lang=js"
import style0 from "./ProductPopularSliderMobile.vue?vue&type=style&index=0&id=130f2999&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "130f2999",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIImg: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UIImg.vue').default,AppBacklight: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/AppBacklight.vue').default,ImageBottomGradient: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/ImageBottomGradient.vue').default,UIIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UIIcon.vue').default,SliderFraction: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/shared/SliderFraction.vue').default})
